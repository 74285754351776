
<div class="row">
  <div class="col-md-12 cajaHeader">
    <nav class="navbar navbar-expand-lg navbar-light bg-light redondear" >
      <a class="navbar-brand" href="#" style="font-size: 18px;">Inicio</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-item nav-link active" href="javascript:void(0)" (click)="muestra();" style="font-size: 18px;">Cartas <span class="sr-only">(current)</span></a>
          <a class="nav-item nav-link" (click)="salir()" style="font-size: 18px;"> Salir</a>        
        </div>
      </div>
    </nav>
  </div>
</div>


<ng-container *ngIf="mostrar == true" #container class="container">
  
  <div class="row">                    
    <div class="col-md-12 cajaMenu">
      
      <table>
        <tr>
          <th style="color: rgb(163, 87, 0)">Cartas</th>
          <th></th>
        </tr>
        <tr *ngFor="let c of cartas">
          <td style="color: darkblue; background-color: rgba(255, 255, 234, 0.418); border-radius: 18px;padding: 6px; margin: 2px;">
            <a class="link" routerLink="/main/cartas/{{c.CarIdeCar}}" (click)="esconder();">{{c.CarNomCar}}</a>
          </td>
        </tr>
      </table>
      
    </div>
  </div>

</ng-container>


<router-outlet></router-outlet> 

