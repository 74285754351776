import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { CartaDetalleComponent } from './carta-detalle/carta-detalle.component';


const routes: Routes = [
  { path: '', component: LoginComponent },  
  { path: 'main', component: MainComponent,
  children: [
    {path: 'cartas/:id', component: CartaDetalleComponent}]
  },
  
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
