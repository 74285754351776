import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { CartaDetalleComponent } from './carta-detalle/carta-detalle.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SrCartasService } from './sr-cartas.service';
import { RecaptchaModule } from 'angular-google-recaptcha';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,    
    MainComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    CartaDetalleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
        RecaptchaModule.forRoot({
            siteKey: '6LfWccIZAAAAAK3VPiltJ21PWkUG2_6mVf4xSLOl',
        }),
        
  ],
  providers: [SrCartasService],
  bootstrap: [AppComponent]
})
export class AppModule { }
