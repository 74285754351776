<div class="container">
    
       
            <div class="row">                    
                <div class="col-md-12 header" >
                    <img src="assets/nuevo_logo_pagina_web.png"  class="imgHeader img-fluid">
                    <div class="tituloHeader"><h3> Cartas Online</h3></div>
                </div>
            </div>
   
   
                                
                
            <app-menu></app-menu>
              
           

    
</div>

<router-outlet></router-outlet> 
    