import { Injectable } from '@angular/core';
import { Http, HttpModule, Response, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Usuario } from './clases/usuario';
import { SrGlobalService } from './sr-global.service';



@Injectable({
  providedIn: 'root'
})
export class SrLoginService {

  public url: string;

  constructor(private http: Http, global: SrGlobalService) { 
    this.url = global.URL_REST;
  }

  intentaLogin(email: string, pass: string) {
    var user: Usuario;
    user = new Usuario();

    user.UsrEmail = email;
    user.UsrPass = pass;

    // public UsrIdeUsr: string;
    // public UsrCliUsr: string;
    // public UsrEmail: string;
    // public UsrPass: string;
    // public UsrRolUsr: string;

   var params = JSON.parse(JSON.stringify(user));

    return this.http
    .post(this.url + '/intentaLogin', params)
    .pipe(map(res => res.json()));
 
  }
  
}
