export class Producto {

    public ProIdePro : string;
    public ProCliIde : string;
    public ProCarIde : string;
    public ProGrpIde : string;
    public ProNomPro : string;
    public ProDesCorta : string;
    public ProDesLarga : string;
    public ProPrePro : string;
    public ProOrdPro : string;
    public ProImgPro : string;
    public ProImgFisica : string;
    public ProCssNombre : string;
    public ProCssDescrip : string;
    public ProCssPrecio : string;
    public ProCssBackg : string;
    public ProActPro : string;

}
