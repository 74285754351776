import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SrLoginService } from 'src/app/sr-login.service';
import { SrGlobalService } from 'src/app/sr-global.service';
import { Usuario } from '../clases/usuario';
import { stringify } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  constructor(private srLogin: SrLoginService, private srGlobal: SrGlobalService, private router: Router) { }
  
  @ViewChild('elEmail') elEmail: ElementRef;
  @ViewChild('laPass') laPass: ElementRef;
  public mensaje: string;
  myRecaptcha = new FormControl(false);
  
  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    
    this.elEmail.nativeElement.focus();
    this.elEmail.nativeElement.select();
    this.elEmail.nativeElement.value = localStorage.getItem('UserEmail');
    //this.elEmail.nativeElement.value = 'snaranjo@gmail.com';
    //this.laPass.nativeElement.value = 'pruebas';
  }
  
  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }
  
  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }
  
  
  public onKeydownLogin(event) {
    if (event.key === 'Enter') {
      this.laPass.nativeElement.focus();
      this.laPass.nativeElement.select();
    }
  }
  
  public onKeydownPass(event) {
    if (event.key === 'Enter') {
      this.intentaLogin();
    }
  }
  
  public intentaLogin() {
    
    if (this.srGlobal.URL.indexOf('localhost') == -1) { // osea, si estamos en produccion
      if (this.myRecaptcha.valid == false) {
        this.mensaje = 'Debe indicar si es humano.';
        this.elEmail.nativeElement.focus();      
        return;
      }
    }
    
    localStorage.removeItem('isLoggedin');
    sessionStorage.removeItem('UserId');    
    sessionStorage.removeItem('UserEmail');
    sessionStorage.removeItem('UserCliId');
    
    
    this.mensaje = '';
    
    if (this.elEmail.nativeElement.value == undefined || this.elEmail.nativeElement.value == '') {
      this.mensaje = 'Debe indicar email';
      this.elEmail.nativeElement.focus();      
      return;
    }
    
    if (!this.srGlobal.EsEmailValido(this.elEmail.nativeElement.value)){
      this.mensaje = 'Debe indicar un email válido';
      this.elEmail.nativeElement.focus();
      this.elEmail.nativeElement.select();
      return;
    }
    
    if (this.laPass.nativeElement.value == undefined || this.laPass.nativeElement.value == '') {
      this.mensaje = 'Debe indicar su password';
      this.laPass.nativeElement.focus();      
      return;
    }
    
    
    const dat = this.srLogin.intentaLogin(this.elEmail.nativeElement.value, this.laPass.nativeElement.value).subscribe(
      result => {
        if (this.srGlobal.HayRespuesta(result)) {          
          
          if (result.message.length == 1) {  // INGRESO CORRECTO
            
            let usuario: Usuario;
            usuario = result.message[0];
            
            this.mensaje = '';
            localStorage.setItem('UserEmail', this.elEmail.nativeElement.value);
            sessionStorage.setItem('UserEmail', this.elEmail.nativeElement.value);
            sessionStorage.setItem('UserId', usuario.UsrIdeUsr);
            sessionStorage.setItem('UserCliId', usuario.UsrCliIde);
            
            
            this.router.navigate(['/main']);
            
          } 
          else
          {            
            sessionStorage.removeItem('UserId');            
            sessionStorage.removeItem('UserEmail');
            sessionStorage.removeItem('UserCliId');
            
            this.mensaje = 'Login o Password incorrecta.';
            this.laPass.nativeElement.focus();
            this.laPass.nativeElement.select();
            return;
          }          
        } else {          
          console.log('Error al consultar');
        }
      },
      error => {
        const errorMessage = <any>error;
        console.log(errorMessage);
      }
      );
    }
    
  }
  