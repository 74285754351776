<div class="container">
    
    <div class="row">
        <div class="col-md-3">
            
        </div>
        
        <div class="col-md-6">
            <div class="row">                    
                <div class="col header">
                    <img src="assets/nuevo_logo_pagina_web.png"  class="img-fluid">
                    <h1> Cartas Online - 3Pro</h1>
                </div>
            </div>
            
            <hr>
            
            <div class="row">
                <div class="col caja">
                    
                    <form>
                        <div class="form-group">
                            <label for="exampleInputEmail1">E-mail</label>
                            <input type="email" class="form-control" #elEmail id="elEmail" aria-describedby="emailHelp" placeholder="su correo">
                            <small id="emailHelp" (keyup)="mensaje=''" (keydown)="onKeydownLogin($event)" class="form-text text-muted">Nunca compartiremos tu email con alguien más.</small>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <input type="password" (keyup)="mensaje=''" (keydown)="onKeydownPass($event)" class="form-control" #laPass id="laPass" placeholder="su password">
                        </div>
                        <!-- <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Recordarme</label>
                        </div> -->

                        <recaptcha
                            [formControl]="myRecaptcha"
                            (scriptLoad)="onScriptLoad()"
                            (scriptError)="onScriptError()"
                        ></recaptcha>
                        
                        <button type="button" class="btn btn-primary" (click)="intentaLogin()">Ingresar</button>
                    </form>
                    
                    <div *ngIf="mensaje" style="padding: 0px; margin: 0px">
                        <span class="badge badge-danger">{{mensaje}}</span>
                    </div>
                    
                </div>
            </div>
            

        
        <div class="col-md-3">
            
        </div>
        
    </div>
</div>
