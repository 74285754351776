import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SrGlobalService {
  // public URL_REST = 'http://localhost:5656';
  // public URL = 'http://localhost';

  //public URL_REST = 'http://cartasonline.cl:5656';
  //public URL = 'http://cartasonline.cl';

  // public URL_REST = 'https://api-cartas.azurewebsites.net';
  // public URL = 'https://api-cartas.azurewebsites.net';

  public URL_REST = 'http://104.168.152.146:5656';
  public URL = 'http://cartasonline.cl:5656';

  constructor() {}

  public HayRespuesta(result: any) {
    if (!result) {
      console.log('Sin respuesta');
      return false;
    }

    if (result == undefined || result === undefined) {
      console.log('Resultado indefinido');
      return false;
    }

    if (result.code != 200) {
      console.log('Respuesta no es 200:');
      console.log('Mensaje: ' + result.message);
      return false;
    }

    return true;
  }

  public EsEmailValido(email: string): boolean {
    let mailValido = false;
    ('use strict');

    var EMAIL_REGEX =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.match(EMAIL_REGEX)) {
      mailValido = true;
    }
    return mailValido;
  }
}
