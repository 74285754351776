import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { SrCartasService } from '../sr-cartas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SrGlobalService } from '../sr-global.service';
import { Grupo } from '../clases/grupo';
import { Producto } from '../clases/producto';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-carta-detalle',
  templateUrl: './carta-detalle.component.html',
  styleUrls: ['./carta-detalle.component.css']
})
export class CartaDetalleComponent  {
  
  
  
  public isCollapsed = false;
  
  public grupos: any;
  public productos: any;
  
  private cartaid: string;
  private idCli = sessionStorage.getItem('UserCliId');
  public NombreGrupo: string;
  
  //seleccion de grupo
  public selec_grupo_id: string;
  public selec_grupo_nombre: string;
  public selec_grupo_activo: string;
  
  //seleccion de imagen
  public selec_imagen: string;  
  public problema_con_imagen: boolean = false;
  public imagen_seleccionada: File = null;
  public imagen_grabada: string = '';
  public imagen_nombre_original: string = '';
  
  //seleccion de producto
  public selec_id : string;
  public selec_nombre: string;
  public selec_descCorta : string;
  public selec_descLarga : string;
  public selec_Precio : string;
  public selec_activo: string;
  
  
  //creacion - edicion
  public habilita_nuevo_grupo = false;
  public nombre_grupo_nuevo: string;  
  
  // mensajes de error
  public mensaje: string = '';
  public mensaje_grupo: string = '';
  public mensaje_imagen: string = '';
  
  
  @ViewChild('producto') elproducto: ElementRef;
  @ViewChild('precio') elprecio: ElementRef;
  @ViewChild('corta') lacorta: ElementRef;
  @ViewChild('larga') lalarga: ElementRef;
  @ViewChild('activo') elactivo: ElementRef;
  
  
  constructor(
    private router: Router,
    private srCarta: SrCartasService, 
    private srGlobal: SrGlobalService, 
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
    ) { 
    this.activatedRoute.params.subscribe(params => {
      this.cartaid = params['id'];

      this.grupos = null;
      this.productos = null;

      this.ObtieneGrupos(this.cartaid);
      this.mensaje = "";
      this.mensaje_grupo = "";

      this.selec_grupo_id = undefined;   
      this.mensaje = ""; 
      this.mensaje_grupo = "";

      

    });      
  }
  
  // ngOnInit(): void {
    
  //   this.ObtieneGrupos(this.cartaid);
  //   this.mensaje = "";
  //   this.mensaje_grupo = "";
    
  // }
  
  // ngAfterViewInit(): void {
  //   this.selec_grupo_id = undefined;   
  //   this.mensaje = ""; 
  //   this.mensaje_grupo = "";
  // }
  

  onFileSelected(event) {
     console.log(event);
     
     if (event.target.files[0].type != 'image/jpeg'){
      console.log('Archivo inválido.');
      this.mensaje_imagen = "Solo se permiten imágenes JPG. Máximo 200 Kb y cuadradas.";
      this.problema_con_imagen = true;
     }
     else if (event.target.files[0].size > 220000) {
       console.log('Demasiado grande.');
       this.mensaje_imagen = "Demasiado grande. Máximo 200 Kb y cuadrada.";
       this.problema_con_imagen = true;
     }     
     else {
      this.mensaje_imagen = '';
      this.problema_con_imagen = false;
      this.imagen_seleccionada = <File>event.target.files[0];
     }
  }


  
  private ObtieneGrupos(idCarta: string) {    
    this.mensaje = "";
    this.mensaje_grupo = "";
    
    this.selec_id = undefined;
    this.selec_nombre = undefined;
    
    if (idCarta != null && idCarta != '') {
      const dat2 = this.srCarta.getGrupos(this.idCli, idCarta).subscribe(result => {
        if (this.srGlobal.HayRespuesta(result)) {
          if (result.message.length === 0) {
            this.grupos = null;                
          } else {                
            this.grupos = result.message;                
          }
        } else {
          console.log('Error al consultar');
        }
      },
      error => {
        const errorMessage = <any>error;
        console.log(errorMessage);
      }
      );
    }
  }
  
  public ObtieneProductos(idGrupo: string, nombreGrupo: string) {
    
    this.mensaje = "";
    this.mensaje_grupo = "";

    this.habilita_nuevo_grupo = false;
    
    this.selec_id = undefined;
    this.selec_nombre = undefined;
    this.selec_grupo_id = idGrupo;
    
    this.selec_grupo_nombre = nombreGrupo;
    this.selec_grupo_id = idGrupo;
    this.selec_grupo_activo = this.grupos.find(element => element.GrpIdeGrp == idGrupo).GrpActGrp;
    
    if (idGrupo != null && idGrupo != '') {
      this.NombreGrupo = nombreGrupo;
      const dat2 = this.srCarta.getProductos(this.idCli, this.cartaid, idGrupo).subscribe(result => {
        if (this.srGlobal.HayRespuesta(result)) {
          if (result.message.length === 0) {
            this.productos = null;                
          } else {                
            this.productos = result.message;                
          }
        } else {
          console.log('Error al consultar');
        }
      },
      error => {
        const errorMessage = <any>error;
        console.log(errorMessage);
      }
      );
    }
  }
  
  
  public SeleccionaProd(id: string, nombre: string) {
    
    this.mensaje = "";
    this.mensaje_grupo = "";

    this.habilita_nuevo_grupo = false;
    
    this.selec_id = id;
    this.selec_nombre = nombre;
    
    this.productos.forEach(prod => {
      if (prod.ProIdePro == this.selec_id) {
        this.selec_nombre = prod.ProNomPro;
        this.selec_descCorta = prod.ProDesCorta;
        this.selec_descLarga = prod.ProDesLarga;
        this.selec_Precio = prod.ProPrePro;
        this.selec_activo = prod.ProActPro;
        this.imagen_grabada = this.srGlobal.URL + prod.ProImgFisica;
        this.imagen_nombre_original = prod.ProImgPro;

      }
      
    });
    
  }
  
  
  public GuardarGrupoNuevo() {
    
    this.mensaje = "";
    this.mensaje_grupo = "";

    if (this.idCli == undefined || this.idCli == '') {
      return;
    }
    if (this.cartaid == undefined || this.cartaid == '') {
      return;
    }
    if (this.nombre_grupo_nuevo == undefined || this.nombre_grupo_nuevo == '') {
      return;
    }
    
    var grupo = new Grupo;
    grupo.GrpCliIde = this.idCli;
    grupo.GrpCarIde = this.cartaid;
    grupo.GrpNomGrp = this.nombre_grupo_nuevo;
    
    this.srCarta.grabaGrupoNuevo(grupo).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');  
          this.habilita_nuevo_grupo = false;
          this.ObtieneGrupos(this.cartaid);
          
        } else {                
          console.log('Error al grabar: ' + result.message);  
        }
      } else {
        console.log('Error al grabar');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
  }
  
  
  public sube() {
    
    if (this.selec_grupo_id == undefined || this.selec_grupo_id == null) {
      return;
    }
    
    var grp = new Grupo();
    grp.GrpCliIde = this.idCli;
    grp.GrpCarIde = this.cartaid;
    grp.GrpIdeGrp = this.selec_grupo_id;
    
    this.srCarta.sube(grp).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');  
          this.habilita_nuevo_grupo = false;
          this.ObtieneGrupos(this.cartaid);
        } else {                
          console.log('Error al subir: ' + result.message);  
        }
      } else {
        console.log('Error al subir');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }
  
  public baja() {
    
    if (this.selec_grupo_id == undefined || this.selec_grupo_id == null) {
      return;
    }
    
    var grp = new Grupo();
    grp.GrpCliIde = this.idCli;
    grp.GrpCarIde = this.cartaid;
    grp.GrpIdeGrp = this.selec_grupo_id;
    
    this.srCarta.baja(grp).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');  
          this.habilita_nuevo_grupo = false;
          this.ObtieneGrupos(this.cartaid);
        } else {                
          console.log('Error al bajar: ' + result.message);  
        }
      } else {
        console.log('Error al bajar');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }
  
  
  public subeProd() {
    
    if (this.selec_id == undefined || this.selec_id == null) {
      return;
    }
    
    var pro = new Producto();
    pro.ProCliIde = this.idCli;
    pro.ProCarIde = this.cartaid;
    pro.ProGrpIde = this.selec_grupo_id;
    pro.ProIdePro = this.selec_id;
    
    this.srCarta.subeProd(pro).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');            
          this.ObtieneProductos(this.selec_grupo_id, this.NombreGrupo);
          this.SeleccionaProd(pro.ProIdePro, this.selec_nombre);
        } else {                
          console.log('Error al subir: ' + result.message);  
        }
      } else {
        console.log('Error al subir');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }
  
  public bajaProd() {
    
    if (this.selec_id == undefined || this.selec_id == null) {
      return;
    }
    
    var pro = new Producto();
    pro.ProCliIde = this.idCli;
    pro.ProCarIde = this.cartaid;
    pro.ProGrpIde = this.selec_grupo_id;
    pro.ProIdePro = this.selec_id;
    
    this.srCarta.bajaProd(pro).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');
          this.ObtieneProductos(this.selec_grupo_id, this.NombreGrupo);
          this.SeleccionaProd(pro.ProIdePro, this.selec_nombre);
        } else {                
          console.log('Error al bajar: ' + result.message);  
        }
      } else {
        console.log('Error al bajar');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }
  
  public HabilitaNuevoProducto() {
    
    this.mensaje = "";
    this.mensaje_grupo = "";
    
    if (this.idCli == undefined || this.idCli == '' ||
    this.selec_grupo_id == undefined || this.selec_grupo_id == '' ||
    this.cartaid == undefined || this.cartaid == ''
    ) {
      return;
    }
    
    
    this.selec_id = '0';
    this.selec_nombre = '';
    this.selec_descCorta = '';
    this.selec_descLarga = '';
    this.selec_Precio = '';
    this.selec_activo = 'SI';
    this.selec_imagen = '' ; 
    this.problema_con_imagen = false;
    this.imagen_seleccionada = null;
    this.imagen_grabada = '' 
    this.imagen_nombre_original = '';
    this.elproducto.nativeElement.focus();    
  }
  
  public GrabaProducto() {
    
    if (this.selec_id == undefined || this.selec_id == ''){
      this.mensaje = 'falta id';
      return;
    }
    
    if (this.idCli == undefined || this.idCli == ''){
      this.mensaje = 'falta cliente';
      return;
    }
    
    if (this.cartaid == undefined || this.cartaid == ''){
      this.mensaje = 'falta carta';
      return;
    }
    
    if (this.selec_grupo_id == undefined || this.selec_grupo_id == ''){
      this.mensaje = 'falta grupo';
      return;
    }
    
    if (this.elproducto == undefined || this.elproducto.nativeElement.value == ''){
      this.mensaje = 'falta nombre del producto';
      return;
    }
    
    if (this.elprecio == undefined || this.elprecio.nativeElement.value == ''){
      this.mensaje = 'falta precio del producto';
      return;
    }

    if (this.problema_con_imagen){
      this.mensaje = 'problema con la imágen';
      return;
    }
    
    var prod = new Producto();
    prod.ProIdePro = this.selec_id;
    prod.ProCliIde = this.idCli;
    prod.ProCarIde = this.cartaid;
    prod.ProGrpIde = this.selec_grupo_id;
    prod.ProNomPro = this.elproducto.nativeElement.value;
    prod.ProDesCorta = this.lacorta.nativeElement.value;
    //prod.ProDesLarga = this.lalarga.nativeElement.value;
    prod.ProPrePro = this.elprecio.nativeElement.value;
    prod.ProActPro = this.elactivo.nativeElement.value;
    prod.ProImgPro = this.imagen_nombre_original;
    
    this.srCarta.GrabaProd(prod).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){

          console.log('datos guardados ok. Subiendo archivo..');


          var id_producto: string;
          
          if (prod.ProIdePro == '0')
            id_producto = result.message;
          else 
            id_producto = prod.ProIdePro;


          if (this.imagen_seleccionada != null && this.imagen_seleccionada != undefined && this.problema_con_imagen == false) {
            const fd = new FormData();
            fd.append('image', this.imagen_seleccionada, this.imagen_seleccionada.name);
            fd.append('cliente', this.idCli);
            fd.append('carta', this.cartaid);
            fd.append('prod', id_producto);
            this.srCarta.subeImagen(fd).subscribe(result => {
              
              console.log('Resultado de la subida: ' + result);
              console.log('Refrescando lista de productos...');
              this.ObtieneProductos(this.selec_grupo_id, this.NombreGrupo);
              this.mensaje = "";

            });          
        }
        else {
          console.log('Refrescando lista de productos...');
          this.ObtieneProductos(this.selec_grupo_id, this.NombreGrupo);
          this.mensaje = "";
        }
          
        } else {                
          console.log('Error al bajar: ' + result.message);  
        }
      } else {
        console.log('Error al bajar');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }

  onUpload() {
    const fd = new FormData();
    
    fd.append('image', this.imagen_seleccionada, this.imagen_seleccionada.name);
    // fd.append('cliente', this.idCli);
    // fd.append('carta', this.cartaid);
    // fd.append('prod', prod)

    this.srCarta.subeImagen(fd).subscribe(result => {

      console.log(result);

      // if (this.srGlobal.HayRespuesta(result)) {

        

      //   if (result.message.length === 0) {
          
      //   } else {                
          
      //   }
      // }
    });

}
  
  public ActualizarGrupo() {
    
    if (this.selec_grupo_id == undefined || this.selec_grupo_id == '') {
      this.mensaje_grupo = "Falta el id";
      return;
    }
    
    if (this.selec_grupo_nombre == undefined || this.selec_grupo_nombre == '') {
      this.mensaje_grupo = "Falta el nombre del grupo";
      return;
    }
    
    if (this.selec_grupo_activo == undefined || this.selec_grupo_activo == '') {
      this.mensaje_grupo = "Falta activo SI o NO";
      return;
    }
    
    var gru = new Grupo();
    gru.GrpIdeGrp = this.selec_grupo_id;
    gru.GrpNomGrp = this.selec_grupo_nombre;
    gru.GrpActGrp = this.selec_grupo_activo;
    
    this.srCarta.ActuzalizaGrupo(gru).subscribe(result => {
      if (this.srGlobal.HayRespuesta(result)) {
        if (result.code == '200'){
          console.log('ok');
          this.ObtieneGrupos(this.cartaid);
          this.mensaje_grupo = "";          
        } else {                
          console.log('Error al traer grupos: ' + result.message);  
        }
      } else {
        console.log('Error al traer grupos');
      }
    },
    error => {
      const errorMessage = <any>error;
      console.log(errorMessage);
    }
    );
    
  }
  
}
