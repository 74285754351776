import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SrCartasService } from '../sr-cartas.service';
import { SrGlobalService } from '../sr-global.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public cartas : any;
  public mostrar : boolean = true;

  constructor(private router: Router, private srCartas: SrCartasService, private srGlobal: SrGlobalService) { }

  ngOnInit(): void {
    var idUser = sessionStorage.getItem('UserId');
    var idCliente = sessionStorage.getItem('UserCliId');
    this.ObtieneCartas(idCliente);
  }

  public salir() {
    localStorage.removeItem('isLoggedin');
    sessionStorage.removeItem('UserId');    
    sessionStorage.removeItem('UserEmail');
   
    this.router.navigate(['/']);

  }

  private ObtieneCartas(idCliente: string) {
    if (idCliente != null && idCliente != '') {
      const dat2 = this.srCartas.getCartasCliente(idCliente).subscribe(result => {
            if (this.srGlobal.HayRespuesta(result)) {
              if (result.message.length === 0) {
                this.cartas = null;                
              } else {                
                this.cartas = result.message;                
              }
            } else {
              console.log('Error al consultar');
            }
          },
          error => {
            const errorMessage = <any>error;
            console.log(errorMessage);
          }
        );
    }
  }

  public esconder() {
    this.mostrar = false;
  }

  public muestra() {
    this.mostrar = true;
  }

}
