export class Grupo {

    public GrpIdeGrp : string;
    public GrpCliIde : string;
    public GrpCarIde : string;
    public GrpNomGrp : string;
    public GrpCssNom : string;
    public GrpCssNomBackgr : string;
    public GrpCssGrpBackgr : string;
    public GrpCssProducto : string;
    public GrpCssPrecio : string;
    public GrpOrdGrp : string;
    public GrpActGrp : string;

}
