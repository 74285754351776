<div class="container">
  <div class="row">
    <div class="col-md-3 caja">
      <table>
        <tr>
          <th>
            Grupos &nbsp;
            <span>
              <a
                href="javascript:void(0)"
                (click)="habilita_nuevo_grupo = !habilita_nuevo_grupo"
                ><i class="fa fa-plus-circle" style="color: green"></i
              ></a> </span
            >&nbsp;
            <span>
              <a href="javascript:void(0)" (click)="sube()"
                ><i class="fa fa-arrow-up" style="color: rgb(46, 72, 223)"></i
              ></a>
            </span>
            <span
              >&nbsp;
              <a href="javascript:void(0)" (click)="baja()"
                ><i class="fa fa-arrow-down" style="color: rgb(46, 72, 223)"></i
              ></a>
            </span>
          </th>
        </tr>
        <div *ngIf="habilita_nuevo_grupo == true">
          <input
            type="text"
            class="form-control"
            id="nombre_grupo_nuevo"
            [(ngModel)]="nombre_grupo_nuevo"
            maxlength="25"
          />
          <button class="btn btn-success" (click)="GuardarGrupoNuevo()">
            Guardar
          </button>
        </div>
        <tr *ngFor="let g of grupos">
          <td>
            <span *ngIf="g.GrpIdeGrp == selec_grupo_id" style="color: orange">
              <i class="fa fa-caret-right"></i> </span
            >&nbsp;
            <a
              *ngIf="g.GrpActGrp == 'SI'"
              href="javascript:void(0)"
              (click)="ObtieneProductos(g.GrpIdeGrp, g.GrpNomGrp)"
            >
              {{ g.GrpNomGrp }}
            </a>
            <a
              *ngIf="g.GrpActGrp == 'NO'"
              href="javascript:void(0)"
              (click)="ObtieneProductos(g.GrpIdeGrp, g.GrpNomGrp)"
              style="color: rgb(175, 175, 175)"
            >
              {{ g.GrpNomGrp }}
            </a>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-md-9 caja">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab class="tab1">
          <ng-template ngbTabTitle>
            <b>Productos</b>&nbsp;
            <span>
              <a href="javascript:void(0)" (click)="HabilitaNuevoProducto()"
                ><i class="fa fa-plus-circle" style="color: green"></i
              ></a> </span
            >&nbsp;
            <span>
              <a href="javascript:void(0)" (click)="subeProd()"
                ><i class="fa fa-arrow-up" style="color: rgb(46, 72, 223)"></i
              ></a>
            </span>
            <span
              >&nbsp;
              <a href="javascript:void(0)" (click)="bajaProd()"
                ><i class="fa fa-arrow-down" style="color: rgb(46, 72, 223)"></i
              ></a>
            </span>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="row">
              <div class="col-md-4">
                <table>
                  <tr *ngFor="let p of productos">
                    <td>
                      <span
                        *ngIf="p.ProIdePro == selec_id"
                        style="color: orange"
                      >
                        <i class="fa fa-caret-right"></i> </span
                      >&nbsp;
                      <a
                        *ngIf="p.ProActPro == 'SI'"
                        href="javascript:void(0)"
                        (click)="SeleccionaProd(p.ProIdePro, p.ProNomPro)"
                        >{{ p.ProNomPro }}</a
                      >
                      <a
                        *ngIf="p.ProActPro == 'NO'"
                        href="javascript:void(0)"
                        (click)="SeleccionaProd(p.ProIdePro, p.ProNomPro)"
                        style="color: rgb(175, 175, 175)"
                        >{{ p.ProNomPro }}</a
                      >
                    </td>
                  </tr>
                </table>
              </div>

              <div
                *ngIf="selec_id != undefined && selec_id != ''; then deta"
              ></div>

              <ng-template #deta>
                <div class="col-md-8">
                  <table class="detalle">
                    <tr>
                      <td class="titulos">Producto</td>
                      <td>
                        <input
                          type="text"
                          #producto
                          class="form-control"
                          value="{{ selec_nombre }}"
                          maxlength="50"
                          style="font-weight: bold"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="titulos">Descripción Corta</td>
                      <td>
                        <textarea
                          #corta
                          class="form-control"
                          id="descCorta"
                          rows="6"
                          maxlength="250"
                          >{{ selec_descCorta }}</textarea
                        >
                      </td>
                    </tr>
                    <!-- <tr>
                                            <td class="titulos">Descripción Larga</td>
                                            <td><textarea #larga class="form-control" id="descLarga" rows="3" maxlength="150">{{selec_descLarga}}</textarea></td>
                                        </tr> -->
                    <tr>
                      <td class="titulos">Precio</td>
                      <td>
                        <input
                          type="text"
                          #precio
                          class="form-control"
                          value="{{ selec_Precio }}"
                          maxlength="50"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="titulos">Imágen</td>
                      <td>
                        <input
                          type="file"
                          accept=".jpg, .jpeg"
                          #imagen
                          id="imagen"
                          class="form-control"
                          value="{{ selec_imagen }}"
                          maxlength="50"
                          (change)="onFileSelected($event)"
                        />
                        <div
                          *ngIf="mensaje_imagen"
                          style="padding: 0px; margin: 0px; text-align: right"
                        >
                          <span class="badge badge-danger">{{
                            mensaje_imagen
                          }}</span>
                        </div>
                      </td>
                    </tr>

                    <ng-container *ngIf="imagen_nombre_original">
                      <tr>
                        <td class="titulos">Imagen</td>
                        <td>
                          <div class="row">
                            <div
                              class="col-md-4"
                              style="align-self: baseline; padding-top: 4px"
                            >
                              <img
                                src="{{ imagen_grabada }}"
                                width="100px"
                                height="100px"
                                style="border-radius: 5px; padding-bottom: 4px"
                              />
                            </div>
                            <div class="col-md-6">
                              {{ imagen_nombre_original }}
                            </div>
                            <div class="col-md-2">
                              <button
                                class="btn form-control btn-danger"
                                (click)="imagen_nombre_original = ''"
                              >
                                <i class="fa fa-close"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                    <tr>
                      <td class="titulos">Activo</td>
                      <td>
                        <select #activo class="form-control" id="activo">
                          <option value="SI" [selected]="selec_activo == 'SI'">
                            SI
                          </option>
                          <option value="NO" [selected]="selec_activo != 'SI'">
                            NO
                          </option>
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td style="text-align: right">
                        <Button
                          class="btn btn-success"
                          (click)="GrabaProducto()"
                          >Grabar</Button
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div
                          *ngIf="mensaje"
                          style="padding: 0px; margin: 0px; text-align: right"
                        >
                          <span class="badge badge-danger">{{ mensaje }}</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ngb-tab>

        <ngb-tab>
          <ng-template ngbTabTitle>
            <i
              *ngIf="NombreGrupo != undefined"
              class="fa fa-cogs"
              style="color: rgb(175, 114, 0)"
            ></i>
            <span class="nombreGrupo"
              ><b> {{ NombreGrupo }} </b></span
            >
          </ng-template>

          <ng-template ngbTabContent>
            <p>Edicion del Grupo</p>
            <table>
              <tr>
                <td>Grupo</td>
                <td>
                  <input
                    type="text"
                    [(ngModel)]="selec_grupo_nombre"
                    class="form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Activo</td>
                <td>
                  <select class="form-control" [(ngModel)]="selec_grupo_activo">
                    <option value="SI" [selected]="selec_grupo_activo == 'SI'">
                      SI
                    </option>
                    <option value="NO" [selected]="selec_grupo_activo != 'SI'">
                      NO
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: right">
                  <button class="btn btn-success" (click)="ActualizarGrupo()">
                    Grabar
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    *ngIf="mensaje_grupo"
                    style="padding: 0px; margin: 0px; text-align: right"
                  >
                    <span class="badge badge-danger">{{ mensaje_grupo }}</span>
                  </div>
                </td>
              </tr>
            </table>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
