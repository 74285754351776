import { Injectable } from '@angular/core';
import { Http, HttpModule, Response, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Usuario } from './clases/usuario';
import { SrGlobalService } from './sr-global.service';
import { Grupo } from './clases/grupo';
import { Producto } from './clases/producto';

@Injectable({
  providedIn: 'root'
})
export class SrCartasService {

  public url: string;
  headers: Headers;

  constructor(private http: Http, global: SrGlobalService) { 
    this.url = global.URL_REST;
  }


  subeImagen(formData) {    
    return this.http
      .post(this.url + '/subir', formData)
      .pipe(map(res => res.json()));
   }

  getCartasCliente(idCliente: string) {
   
   return this.http
   .get(this.url + '/cartas/cliente/' + idCliente)
   .pipe(map(res => res.json()));
 
  }

  getCarta(idCarta: string) {
   
    return this.http
    .get(this.url + '/cartas/' + idCarta)
    .pipe(map(res => res.json()));
  
   }

  getGrupos(idCliente: string, idCarta: string) {
   
    return this.http
    .get(this.url + '/grupos/' + idCliente + '/' + idCarta)
    .pipe(map(res => res.json()));
  
   }

   getProductos(idCliente: string, idCarta: string, idGrupo: string) {
   
    return this.http
    .get(this.url + '/prod/' + idCliente + '/' + idCarta + '/' + idGrupo)  
    .pipe(map(res => res.json()));
  
   }

   grabaGrupoNuevo(grupo : Grupo) {       
    
    var params = JSON.parse(JSON.stringify(grupo));

    return this.http
      .post(this.url + '/grupos/grabanuevo', params)        
      .pipe(map(res => res.json()));

   }

   sube(grupo : Grupo) {       
    
    var params = JSON.parse(JSON.stringify(grupo));

    return this.http
      .post(this.url + '/grupos/sube', params)        
      .pipe(map(res => res.json()));

   }

   baja(grupo : Grupo) {       
    
    var params = JSON.parse(JSON.stringify(grupo));

    return this.http
      .post(this.url + '/grupos/baja', params)        
      .pipe(map(res => res.json()));

   }

   ActuzalizaGrupo(grupo : Grupo) {       
    
    var params = JSON.parse(JSON.stringify(grupo));

    return this.http
      .post(this.url + '/grupos/actualiza', params)        
      .pipe(map(res => res.json()));

   }

  //#region PRODUCTOS

   subeProd(producto : Producto) {       
    
    var params = JSON.parse(JSON.stringify(producto));

    return this.http
      .post(this.url + '/prod/subeProd', params)        
      .pipe(map(res => res.json()));

   }

   bajaProd(producto : Producto) {       
    
    var params = JSON.parse(JSON.stringify(producto));

    return this.http
      .post(this.url + '/prod/bajaProd', params)        
      .pipe(map(res => res.json()));

   }


   GrabaProd(prod : Producto) {       
    
    var params = JSON.parse(JSON.stringify(prod));

    return this.http
      .post(this.url + '/prod/graba', params)        
      .pipe(map(res => res.json()));

   }


   //#endregion

}
